<template>
	<div :key="page.id" class="page">
		<div v-if="page.topPicType==0 && page.topPicUrl && page.topPicUrl.length>0" class="banner">
			<img :src="page.topPicUrl" />
		</div>
		<div v-else-if="page.topPicType==1 && page.carouselList && page.carouselList.length>0" class="carousels">
			<el-carousel :interval="page.topTime*1000" height="32vw">
				<el-carousel-item v-for="(item, index) in page.carouselList" :key="index">
					<div class="carousel_item" :style="'background-image: url('+item.rotationImg+');'" @click="goCarousel(item)">
						<div class="carousel_text">
							<div class="carousel_title">{{item.rotationTitle}}</div>
							<div class="carousel_subtitle">{{item.rotationDesc}}</div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div v-if="page.pageType==1" v-loading="searchLoading" ref="listRef">
			<BlockTitle v-if="page.showTitle==1" :title="page.pageTitle" />
			<component :key="page.listTemplate+'|'+pageNum+'|'+listTime" :is="getListComponent(page.listTemplate)" :data="listData" :pageNum="pageNum" :pageSize="pageSize" :total="total" :loading="searchLoading" from="list"></component>
		</div>
		<template v-else-if="page.pageType==0 && page.contentList && page.contentList.length>0">
			<div v-for="(item, index) in page.contentList" :key="index" :class="((item.showPlat && item.showPlat==1)?' only_pc':'')+((item.showPlat && item.showPlat==2)?' only_mob':'')">
				<BlockTitle v-if="item.isShowTitle==0" :title="item.contentTitle" :link="item.contentLink" />
				<div v-if="item.contentType==0 && item.picUrl && item.picUrl.length>0" class="page_image">
					<img :src="item.picUrl" alt="" />
				</div>
				<div v-else-if="item.contentType==1 && item.richText" class="page_html" v-html="item.richText"></div>
				<div v-else-if="item.contentType==2" class="page_list">
					<component :key="index+'|'+item.listTemplate+'|page'" :is="getListComponent(item.listTemplate)" :data="item.listSettingList"></component>
				</div>
			</div>
		</template>
	</div>
</template>
<script>
	import BlockTitle from './BlockTitle.vue'
	import { getList } from '@/axios/api.js'

	export default {
		name: 'Page',
		props: ['site', 'page'],
		components: {
			BlockTitle
		},
		data() {
			return {
				listComponents: {},
				pageSize: this.page.listSize || 20,
				pageNum: 1,
				total: 0,
				listData: [],
				searchLoading: true,
				listTime: 0
			}
		},
		mounted() {
			this.getListData()
		},
		methods: {
			goCarousel(item) {
				if(item.rotationLink && item.rotationLink.length > 0) {
					this.$router.push({
						path: item.rotationLink
					})
				}
			},
			changePage(num) {
				var top = 0
				if(this.$refs.listRef) {
					top = this.$refs.listRef.offsetTop
				}
				document.documentElement.scrollTop = top
				this.pageNum = num
				this.getListData()
			},
			getListData() {
				if(this.page.pageType == 1) {
					let query = '?id=' + this.page.id + '&current=' + this.pageNum + '&size=' + this.pageSize
					this.searchLoading = true
					getList(query).then(res => {
						if(res.code == 200) {
							res.data.records.map(item => {
								item.listTitle = item.pageTitle
								item.listDesc = item.pageDesc
								item.listImg = item.titleImg
							})
							this.listData = res.data.records
							this.total = res.data.total
							this.listTime = new Date().getTime()
							if(this.listData.length == 0 && this.pageNum > 1) {
								this.pageNum = 1
								this.getListData()
							}
						} else if(res.msg) {
							this.$message.error(res.msg)
						}
						this.searchLoading = false
					}).catch(err => {
						if(err.msg) {
							this.$message.error(err.msg)
						}
						this.searchLoading = false
					})
				}
			},
			getListComponent(theme) {
				theme = this.getTheme(theme, this.site.listTheme)
				if(!this.listComponents['list' + theme]) {
					this.listComponents['list' + theme] = () =>
						import('./list' + theme + '.vue')
				}
				return this.listComponents['list' + theme]
			},
			getTheme(theme, themes) {
				var length = themes.length,
					inThemes = false
				for(var i = 0; i < length; i++) {
					if(!inThemes) {
						if(themes[i] == theme) {
							inThemes = true
							break
						}
					}
				}
				if(!inThemes) theme = themes[0]
				return theme
			}
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		.banner {
			img {
				display: block;
				width: 100%;
			}
		}
		.carousels {
			.carousel_item {
				@include pic_bg_cover;
				height: 100%;
				cursor: pointer;
				position: relative;
				.carousel_text {
					position: absolute;
					left: 30px;
					right: 30px;
					bottom: 30px;
					color: #fff;
					text-align: right;
					.carousel_title {
						font-size: 40px;
					}
					.carousel_subtitle {
						font-size: 20px;
					}
					@media (max-width: $mob-screen) {
						left: 16px;
						right: 16px;
						bottom: 16px;
						.carousel_title {
							font-size: 20px;
						}
						.carousel_subtitle {
							font-size: 14px;
						}
					}
				}
			}
		}
		.only_pc {
			display: block;
			@media (max-width: $mob-screen) {
				display: none;
			}
		}
		.only_mob {
			display: none;
			@media (max-width: $mob-screen) {
				display: block;
			}
		}
		.page_image {
			@include body_block;
			img {
				display: block;
				width: 100%;
			}
		}
		.page_html {
			@include body_block;
			font-size: 18px;
			@media (max-width: $mob-screen) {
				font-size: 16px;
			}
		}
	}
</style>